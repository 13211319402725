.about-section_wrapper {
    border-bottom: 1px solid black;
}

.about-section_grid {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.about-section_content {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-lg);
    margin-left: auto;
}

.about-section_content h1 {
    font-family: Cooper;
    font-weight: normal;
    font-size: var(--text-xl);
    margin: 0;
    color: black;
}

.about-section_content h2 {
    font-family: Sans;
    font-weight: normal;
    font-size: var(--text-md);
    margin: 0;
    color: black;
}

.about-section_content_text {
    font-family: Sans;
    font-size: var(--text-md);
}

.about-section_grid_image {
    background-size: cover;
    background-position: center;
    height: auto;
}

.about-section_grid_image-left {
    background-size: cover;
    background-position: center;
    height: auto;
}

.about-page_people {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background-color: var(--color-green-light);
    border-bottom: 1px solid black;
    padding: var(--spacing-lg);
}

.about-page_people p {
    font-family: Sans;
    font-size: 16px;
    color: var(--color-green);
    margin: 0;
    margin-top: var(--spacing-lg);
    text-align: center;
    max-width: 50em;
    align-self: center;
    padding: 0 var(--spacing-lg);
}

.about-page_people-image {
    align-self: center;
}

.about-page_people-image img {
    display: flex;
    object-fit: contain;
    width: 100%;
    max-width: 50em;
}

@media only screen and (max-width: 1000px) {
    .about-section_grid {
        flex-direction: column;
    }
}

@media only screen and (max-width: 1000px) {
    .about-section_grid_image {
        width: 100%;
        border-top: 1px solid black;
        height: 400px;
    }

    .about-section_grid_image-left {
        width: 100%;
        border-bottom: 1px solid black;
        height: 400px;
    }

    .about-section_content {
        width: calc(100% - 2*var(--spacing-lg));
    }
}

@media only screen and (min-width: 1000px) {
    .about-section_grid_image {
        width: calc(50% - 1px);
        border-left: 1px solid black;
    }

    .about-section_grid_image-left {
        width: 50%;
        border-right: 1px solid black;
    }

    .about-section_content {
        width: calc(50% - 2 * var(--spacing-lg));
        max-width: calc(1400px / 2 - var(--spacing-lg));
    }
}