@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf) format('opentype');
}

@font-face {
    font-family: 'Cooper';
    src: local('sans'), url(../../../fonts/serif.ttf) format('truetype');
}

.ticker_wrapper {
    color: #DA9A15;
    background-color: #FAE5BA;
    padding: var(--spacing-sm) 0px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.ticker_wrapper h1 {
    font-family: Sans;
    font-size: 1.5em;
    font-weight: normal;
    padding: 0;
    margin: 0 0.5em;
}

.ticker_wrapper a {
    color: #DA9A15;
    text-decoration: underline;
}