@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf) format('opentype');
}

.menu-item_container img {
    width: 100%;
}

.menu-item-image {
    height: 300px;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
}

.menu-item_title {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-sm);
    align-items: center;
    margin-top: var(--spacing-sm);
    padding: 0;
}

.menu-item_title h1 {
    font-family: Sans;
    color: black;
    font-size: var(--text-md);
    padding: 0;
    margin: 0;
}

.menu-item_desc h2 {
    font-family: Sans;
    color: black;
    font-weight: normal;
    font-size: var(--text-sm);
    padding: 0;
    margin: 0.5em 0;
}

.menu-item_desc h3 {
    font-family: Sans;
    color: #2F482A;
    font-weight: normal;
    font-size: var(--text-sm);
    padding: 0;
    margin: 0;
}