.location-page_wrapper {
    padding: 1em;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}

.location-page_section {
    display: flex;
    gap: 3em;
    align-items: center;
    justify-content: space-between;
}

.location-page_metro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
    overflow: hidden;
}

.location-page_section img {
    border-radius: 30px;
    display: flex;
    object-fit: contain;
    width: 100%;
}

.location-page_boxes img {
    margin: 2.5vh 0;
    padding: 2em;
    display: flex;
    object-fit: contain;
    width: calc(100% - 3em);
}

.location-page_content {
    display: flex;
    flex-direction: column;
}

.location-page_content h1 {
    font-family: Cooper;
    font-weight: normal;
    margin: 0;
    color: black;
}

.location-page_content p {
    font-family: Sans;
    font-weight: normal;
    font-size: 1em;
    color: black;
    margin: 0;
    margin-top: 1em;
}

.location-page_buttons h1 {
    font-family: Cooper;
    font-weight: normal;
    font-size: 2em;
    margin: 0;
    color: black;
}

@media only screen and (max-width: 900px) {
    .location-page_content h1 {
        font-size: 2em;
    }

  }

@media only screen and (min-width: 901px) {
    .location-page_content h1 {
        font-size: 2.6em;
    }
}
    
.location-page_content h2 {
    font-family: Sans;
    font-weight: normal;
    font-size: 1em;
    color: black;
    margin: 0;
    margin-top: 1em;
}

@media only screen and (max-width: 500px) {
    .location-page_section {
        margin-top: 2em;
        padding: 1em;
    }
}

@media only screen and (min-width: 500px) {
    .location-page_section {
        padding: 2em;
    }
}