@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf);
}

@font-face {
    font-family: 'Cooper';
    src: local('serif'), url(../../../fonts/serif.ttf);
}

.menu-header_wrapper {
    background-color: var(--color-green-light);
    background-size: cover;
    background-position: center;
    height: auto;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
    align-items: stretch;
    gap: var(--spacing-lg)
}

.menu-header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: var(--spacing-lg);
    padding-bottom: var(--spacing-lg);
    
    max-width: calc(1400px /2 );
    margin-left: auto;
}

.menu-header-image-wrapper {
    max-width: calc(1400px /2);
    padding-right: var(--spacing-lg);
    padding-bottom: var(--spacing-lg);
    padding-top: var(--spacing-lg);
}

.menu-header-image {
    background-image: url("./heroimage.png");
    background-size: cover;
    background-position: center;
    height: 100%;
    margin: 0;
    border-radius: 20px;
}

.menu-header_wrapper h1 {
    font-family: Cooper;
    font-weight: normal;
    font-size: var(--text-xxl);
    margin-bottom: 0;
    margin-top: 0;
    color: var(--color-green);
}

.menu-header_wrapper h2 {
    font-family: Sans;
    font-weight: normal;
    font-size: var(--text-md);
    margin-bottom: 0;
    margin-top: 0;
    color: var(--color-green);
}

@media only screen and (max-width: 780px) {
    .menu-header_wrapper {
        flex-direction: column;
    }
}

@media only screen and (max-width: 780px) {
    .menu-header-image-wrapper {
        width: calc(100% - 2*var(--spacing-lg));
        height: 400px;
        padding-top: 0;
        padding-left: var(--spacing-lg);
    }

    .menu-header-content {
        padding-top: var(--spacing-lg);
        padding-bottom: 0;
    }
}

@media only screen and (min-width: 780px) {
    .menu-header-image-wrapper {
        width: calc(1400px /2);
        margin-right: auto;
    }

    .menu-header-content {
        padding-top: 45vh;
    }
}