@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf);
}

@font-face {
    font-family: 'Cooper';
    src: local('serif'), url(../../../fonts/serif.ttf);
}

.menu-highlights_wrapper {
    background-color: #E2D5B7;
    padding: var(--spacing-lg);
    border-bottom: 1px solid black;
}

.menu-highlights_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between  ;
    padding-bottom: 0;
    margin-bottom: var(--spacing-lg);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.menu-highlights_header h1 {
    color: #AD7A44;
    font-family: Cooper;
    font-weight: normal;
    font-size: var(--text-xl);
    padding: 0;
    margin: 0;
    max-width: 10em;
}


.menu-highlights_header_content {
    color: #AD7A44;
    font-family: Sans;
    font-weight: normal;
    font-size: var(--text-md);
    padding: 0;
    width: 50%;
}

.menu-highlights_header_content p {
    margin: 0;
}

.menu-highlights_grid {
    margin: 0 auto;
    display: grid;
    gap: var(--spacing-lg);
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
    max-width: 1400px;
}

@media only screen and (max-width: 850px) {
    .menu-highlights_grid {
        flex-direction: column;
    }

    .menu-highlights_header {
        flex-direction: column;
    }

    .menu-highlights_header_content {
        margin-top: var(--spacing-md);
        width: 100%;
    }

}