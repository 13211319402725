@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf) format('opentype');
}

@font-face {
    font-family: 'Cooper';
    src: local('sans'), url(../../../fonts/serif.ttf) format('truetype');
}

@media only screen and (max-width: 900px) {
    .footer_wrapper {
        flex-direction: column;
    }
}

.footer_wrapper {
    display: flex;
    background-color: #C3CEB5;
    align-items: flex-start;
    justify-content: space-between;
    padding: 3.2em;
    gap: 2em;
}

.footer_section {
    display: flex;
    flex-direction: column;
    gap: 0.3em;
}

.footer_section h3 {
    font-family: Sans;
    font-size: 0.8em;
    font-weight: normal;
    color: #2F482A;
    padding: 0;
    margin: 0;
}

.footer_section h4 {
    font-family: Sans;
    font-size: 0.5em;
    font-weight: normal;
    color: #2F482A;
    padding: 0;
    margin: 0;
}