@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf) format('opentype');
}

.text-button_container p {
    font-family: Sans;
    text-decoration: none;
    margin: 0;
}

@media only screen and (max-width: 520px) {
    .text-button_container p {
        font-size: var(--text-md);
    }
}

@media only screen and (min-width: 520px) {
    .text-button_container p {
        font-size: var(--text-sm);
    }
}

.text-button_container:hover {
    text-decoration: underline;
}