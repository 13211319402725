@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf);
}

@font-face {
    font-family: 'Cooper';
    src: local('serif'), url(../../../fonts/serif.ttf);
}

.header_wrapper {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), rgba(0, 0, 0, 0.35)) , url("./banner.jpeg");
    background-size: cover;
    background-position: center;
    height: auto;
    border-top: 1px solid black;
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: var(--spacing-lg);
    padding-bottom: var(--spacing-lg);
    padding-top: 50vh;
    max-width: calc(1400px + var(--spacing-lg));
    margin-left: auto;
    margin-right: auto;
}

.header_wrapper h1 {
    font-family: Cooper;
    font-weight: normal;
    font-size: var(--text-xxl);
    margin-bottom: 0;
    margin-top: 0;
    color: white;
}