@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../../fonts/sans.otf) format('opentype');
}

.item_container img {
    width: 100%;
}

.item_title {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-xs);
    align-items: flex-start;
    margin-top: var(--spacing-sm);
    padding: 0;
}

.item_title h1 {
    font-family: Sans;
    color: #AD7A44;
    font-weight: normal;
    font-size: var(--text-md);
    padding: 0;
    margin: 0;
}