@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf);
}

@font-face {
    font-family: 'Cooper';
    src: local('serif'), url(../../../fonts/serif.ttf);
}

.about_wrapper {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.about_grid {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.about_content {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-lg);
    margin-left: auto;
}

.about_content h1 {
    font-family: Cooper;
    font-weight: normal;
    font-size: var(--text-xl);
    margin: 0;
    color: black;
}

.about_content_text {
    font-family: Sans;
    font-size: var(--text-md);
    margin: 0;
    margin-top: var(--spacing-md);
}

.about_tiles_container {
    display: flex;
    gap: var(--spacing-md);
    width: 100%;
    margin-top: var(--spacing-md);
}

.about_tile {
    background-color: #C3CEB5;
    color: #2F482A;
    border-radius: var(--spacing-md);
    padding: var(--spacing-md);
    width: 50%;
}

.about_tile p {
    font-family: Cooper;
    font-size: var(--text-md);
    padding: 0;
    margin: 0;
    margin-top: var(--spacing-md);
}

.about_grid_image {
    background-image: url("./cuttingBoard.png");
    background-size: cover;
    background-position: center;
    height: auto;
}

@media only screen and (max-width: 550px) {

    .about_tiles_container {
        flex-direction: column;
    }

    .about_tile {
        width: calc(100% - 2*var(--spacing-md));
    }

}

@media only screen and (max-width: 1000px) {
    .about_grid {
        flex-direction: column;
    }
}

@media only screen and (max-width: 1000px) {
    .about_grid_image {
        width: 100%;
        border-top: 1px solid black;
        height: 400px;
    }

    .about_content {
        width: calc(100% - 2*var(--spacing-lg));
    }
}

@media only screen and (min-width: 1000px) {
    .about_grid_image {
        width: 50%;
        border-left: 1px solid black;
    }

    .about_content {
        width: calc(50% - 2 * var(--spacing-lg));
        max-width: calc(1400px / 2 - var(--spacing-lg));
    }
}