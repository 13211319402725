@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf);
}

@font-face {
    font-family: 'Cooper';
    src: local('serif'), url(../../../fonts/serif.ttf);
}

.menu-section_wrapper {
    margin-bottom: var(--spacing-md);
    padding: var(--spacing-lg);
    max-width: var(--media-xl);
    margin-left: auto;
    margin-right: auto;
}

.menu-section_header {
    margin-bottom: var(--spacing-md);
}

.menu-section_header h1 {
    color: black;
    font-family: Cooper;
    font-weight: normal;
    font-size: var(--text-xl);
    padding: 0;
    margin: 0;
    max-width: 500px;
}

.menu-section_grid {
    margin: 0 auto;
    display: grid;
    gap: var(--spacing-md);
    grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
}
