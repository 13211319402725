:root {
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 48px;
    --spacing-xl: 64px;

    --text-sm: 12px;
    --text-md: 16px;
    --text-xmd: 25px;
    --text-lg: 32px;
    --text-xl: 40px;
    --text-xxl: 48px;

    --media-xl: 1400px;
    --media-lg: 900px;
    --media-md: 600px;
    --media-sm: 520px;

    --color-background: #FEFAF2;
    --color-green: #2F482A;
    --color-green-light: #C3CEB5;
    --color-blue: #2860A1;
    --color-blue-light: #CBD2E1;
    --color-red: #C25637;
    --color-red-light: #EAC4B9;
    --color-brown: #AD7A44;
    --color-brown-light: #EFE2C3;
    --color-yellow: #DA9A15;
    --color-yellow-light: #FAE5BA;
}


@media only screen and (max-width: 600px) {
    :root {
        --spacing-lg: 24px;
        --text-xxl: 42px;
    }
}