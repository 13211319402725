@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf) format('opentype');
}

@font-face {
    font-family: 'Cooper';
    src: local('sans'), url(../../../fonts/serif.ttf) format('truetype');
}

.location_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.location_metro {
    margin: var(--spacing-md) 0;
}

.location_wrapper h1 {
    font-family: Cooper;
    font-size: var(--text-xl);
    font-weight: normal;
    margin: 0;
    margin-top: var(--spacing-lg);
}

.location_wrapper p {
    font-family: Sans;
    font-size: var(--text-md);
    text-align: center;
    padding: var(--spacing-lg);
    padding-top: 0;
}