.navbar_wrapper {
    padding: 0 var(--spacing-lg);
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: var(--spacing-xl);
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.navbar_link-container {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-md);
    align-items: center;
    width: 205px;
}

.navbar_mobile-menu {
    display: flex;
    padding: 0 var(--spacing-lg);
    padding-top: 2em;
    flex-direction: column;
    gap: var(--spacing-md);
}

@media only screen and (max-width: 599px) {
    .navbar_link-container {
        display: none;
    }

    .navbar_wrapper {
        padding-left: var(--spacing-lg);
        padding-right: var(--spacing-lg);
    }
}

.navbar_hamburger {
    cursor: pointer;
}

@media only screen and (min-width: 600px ) {
    .navbar_hamburger {
        display: none;
    }

    .navbar_wrapper {
        padding-left: var(--spacing-lg);
        padding-right: var(--spacing-lg);
    }
}

