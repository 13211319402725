@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf) format('opentype');
}

.pill-button_container {
    border-radius: 1000px;
    width: fit-content;
}

.pill-button_container p {
    font-family: Sans;
    margin: 0;
    text-decoration: none;
}

@media only screen and (max-width: 520px) {
    .pill-button_container p {
        padding: var(--spacing-sm) var(--spacing-md);
        font-size: var(--text-md);
    }
}

@media only screen and (min-width: 520px) {
    .pill-button_container p {
        padding: var(--spacing-xs) var(--spacing-sm);
        font-size: var(--text-sm);
    }
}

.pill-button_container:hover {
    text-decoration: underline;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }