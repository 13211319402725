@font-face {
    font-family: 'Sans';
    src: local('sans'), url(../../../fonts/sans.otf) format('opentype');
}

.badge_container {
    border-radius: 1000px;
    width: fit-content;
    margin: 0;
}

.badge_container p {
    font-family: Sans;
    padding: 2px 5px;
    font-size: 0.8em;
    text-decoration: none;
    margin: 0;
}